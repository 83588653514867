
.main-container {
  background-color: #f3f3f3;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

.login-container {
  border: 1px solid #ccc;
  width: 30em;
  height: 20em;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
}

.black {
  background-color: #101010;
}
.b-radius-20 {
  border-radius: 20%;
}
.m-top-6 {
  margin-top: 6%;
}
.m-50px {
  margin: 50px;
}
.center {
  text-align: center;
}
.h-100vh {
  height: 100vh;
}
.w-100 {
  width: 100%;
}
.w-5 {
  width: 5%;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.login-text {
  font-weight: 500;
  font-size: 25px;
}

.logo-dashboard {
  padding: 20px;
}

.css-gg7n8g-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: white;
  background: white;
}
.css-1tqrhto > .ps-menu-button:hover {
  background-color: #fff;
  color: #000;
}

.ps-menu-root {
  color: #fff;
}

.app .css-vj11vy,
.css-1wvake5,
.css-dip3t8 {
  background: #101010 !important;
}
.css-18unl23,
.css-18unl23 {
  background: #101010;
}
.css-18unl23 {
  background: #101010 !important;
}

.css-1t8x7v1 .ps-menu-button:hover {
  background-color: #f3f3f3;
  color: #000;
}
.css-16jesut > .ps-menu-button:hover {
  background-color: #f3f3f3;
  color: black;
}
h1 {
  color: #165a72;
  margin: 50px auto;
  font-size: 40px;
}
.menu1 {
  margin-bottom: 40px;
  margin-top: 20px;
}
h2 {
  color: #165a72;
}

.css-1wvake5 {
  width: 320px !important;
}

.left-side {
  width: 100%;
}

.css-1pryiuf-MuiPaper-root-MuiAppBar-root {
  background-color: #101010 !important;
}
.css-10ltm04-MuiToolbar-root {
  height: 75px !important;
}

li.MuiListItem-root.MuiListItem-gutters {
  justify-content: center !important;
  margin-top: 10px;
}

.f-w {
  font-weight: 500;
}
.css-svuxz3 {
  width: 100%;
  max-width: 275px;
  background-color: #fff;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 2;
  box-shadow: 1px 1px #adadad;
}

.position-avatar {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

.claimtab {
  margin-top: 20px;
  margin-left: 10px;
  background-color: #fafafa;
  border: 1px solid #d2d4db;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 5px 15px;
  cursor: pointer;
}

.claim-box {
  display: flex;
  flex-wrap: wrap;
}
.input-group-append {
  z-index: 1;
}

.input-group .form-control {
  z-index: 0;
}

