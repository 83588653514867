
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.selected {
  background-color: #EFEFEF !important;
  color: #000 !important;
}


a:hover {
  color: #fff !important;
  font-size: 12px !important;
  background-color: #111111 !important;
}
.menu-anchor:hover {
  background-color: #cafce1 !important;
  font-weight: bolder;
}

.menu-label {
  font-size: 13px;
}

hr {
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0.15;
}
a,
.fpTHfu {
  background-color: #101010 !important;
  font-size: 12px;
  margin-bottom: 10px;
}

.download{
  background:transparent !important;
}
.download:hover{
  background-color: transparent !important;
}
.download1{
  background:transparent !important;
  text-decoration: none !important;
  color:black !important;
}
.download1:hover{
  background:transparent !important;
  text-decoration: none !important;
  color:black !important
}
.certificateDownload{
  background:transparent !important;
  text-decoration: none !important;
  color: #fff !important;
}
.certificateDownload:hover{
  background-color: transparent !important;
  color: #fff !important;
  text-decoration: none;
}
.bgImage {
background-image: url(../src/images/image\ 22.png);
width: 100%;
background-size: cover;
background-position: center;
}
